import React from "react"
import { graphql, Link } from "gatsby"
import LogoDark from "../components/logoDark"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      seoDescription
      publishedDate(formatString: "Do MMMM, YYYY")
      featuredImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const BlogPost = props => {
  return (
    <Layout>
      <SEO
        title={props.data.contentfulBlogPost.title}
        description={props.data.contentfulBlogPost.seoDescription}
      />

      <div className="max-w-2-xl w-full md:max-w-6xl  px-6 mx-auto">
        <div
          className="h-64 blog-large-height bg-cover bg-center rounded-t text-center overflow-hidden"
          style={{
            backgroundImage: `url('${props.data.contentfulBlogPost.featuredImage.fluid.src}')`,
          }}
          title={props.data.contentfulBlogPost.title}
        ></div>
        <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r px-4 md:px-12 pt-4 pb-0 leading-normal">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-xl mb-2">
              {props.data.contentfulBlogPost.title}
            </div>
            <div
              className="text-gray-700 text-base blog"
              dangerouslySetInnerHTML={{
                __html:
                  props.data.contentfulBlogPost.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="flex items-center">
            <LogoDark className="w-10 h-10 rounded-full mr-4" />
            <div className="text-sm ml-4">
              <p className="text-gray-900 leading-none">
                David De Torres <br />
                <span className="leading-tight text-gray-600">
                  {props.data.contentfulBlogPost.publishedDate}
                </span>
              </p>
            </div>
          </div>
          <p className="text-red-600 text-sm text-right">
            <Link to={"/"} title="Go home">
              <span role="img" aria-label="home">
                🏠
              </span>
            </Link>
            &nbsp;
            <Link to={"/blog/"} title="Go back to blog">
              <span role="img" aria-label="close">
                ❌
              </span>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
